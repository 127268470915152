.link:hover {
	text-decoration: underline;
	color: mediumblue;
}
.nav-link {
	color: azure;
	font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
	font-size: 0.8em;
	margin-left: 10px;
	cursor: pointer;
	padding: 0px;
	border: 0px;
}
.nav-link:hover {
	text-decoration: underline;
	color: blue;
	font-size: large;
	background-color: azure;
}
.nav-link {
	transition: 1s;
}
.nav-item.dropdown:hover .dropdown-menu {
	display: block;
}
.dashboard {
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.986);
	transition: box-shadow 0.3s ease-in-out;
	border-radius: 5px;
	cursor: pointer;
	z-index: 5;
	background-color: whitesmoke;
}
.dashboard:hover {
	box-shadow: 0 0 20px rgba(0, 0, 0, 1);
}
.dash-icon {
	font-size: 80px;
}

.dash-text {
	font-size: 20px;
	font-family: Arial, Helvetica, sans-serif;
}
.header {
	max-height: 40px;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 8;
}
.no-caret .dropdown-toggle::after {
	display: none;
}
@media (max-width: 768px) {
	.side-menu {
		height: 100vh;
		position: fixed;
		left: -95vw;
		width: 95vw;
		transition: left 0.3s ease-in-out;
	}
	.side-menu-open {
		left: 0;
		height: 100vh;
		position: fixed;
		width: 95vw;
		transition: left 0.3s ease-in-out;
		z-index: 8;
	}
	.main-page-drawer-open {
		width: 0vw;
		height: 0vh;
		margin-left: 15vw;
		transition: 0.3s ease-in-out;
	}
	.main-page-drawer-closed {
		width: 100vw;
		height: 100vh;
		transition: 0.3s ease-in-out;
	}
	table {
		font-size: xx-small;
		overflow-wrap: break-word;
		word-wrap: break-word;
	}
}
@media (min-width: 768px) {
	.side-menu {
		height: 100vh;
		position: fixed;
		left: -15vw;
		width: 15vw;
		transition: left 0.3s ease-in-out;
	}
	.side-menu-open {
		left: 0;
		height: 100vh;
		position: fixed;
		width: 15vw;
		transition: left 0.3s ease-in-out;
	}
	.main-page-drawer-open {
		width: 85vw;
		height: 100vh;
		margin-left: 15vw;
		transition: 0.3s ease-in-out;
	}
	.main-page-drawer-closed {
		width: 100vw;
		height: 100vh;
		transition: 0.3s ease-in-out;
	}
}
